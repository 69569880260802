import Keys from "../constants/keys";
export default {
  required: (value) => !!value || "This is Required.",
  min5character: (value) =>
    value
      ? value.length > 4 || "Please enter atleast 5 character"
      : "This is Required.",

  notEmpty: (v) => {
    if (v && v.trim() == "") return "Cannot be left empty";
    return true;
  },
  isNum: (v) => {
    if (v.length > 0) if (isNaN(parseInt(v))) return "Invalid Number";
    return true;
  },
  min: (v) => v.length >= 2 || "Min 2 characters",
  email: (v) => {
    /* eslint-disable-next-line no-useless-escape*/
    const re =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(v).toLowerCase().trim())) {
      return true;
    }
    return "Invalid Email";
  },
  mobile: (v) => {
    // if (v >= 2222222222 && v <= 9999999999) return true;
    // return "Invalid Contact";
    console.log(v);
    return true;
  },
  date: (v) => {
    var dateParts = v.split("/");
    var month = parseInt(dateParts[1]);
    var monthDate = parseInt(dateParts[0]);
    if (
      Keys.date_regex.test(v) &&
      monthDate <= 31 &&
      monthDate > 0 &&
      month <= 12 &&
      month > 0
    )
      return true;

    return "Invalid Date Format";
  },
  isUrl: (s) => {
    /* eslint-disable-next-line no-useless-escape*/
    var regexp =/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (regexp.test(s)) return true;

    return "Invalid URL Format";
  },
  dateLessThanToday: (v) => {
    var dateParts = v.split("/");
    var month = parseInt(dateParts[1]);
    var monthDate = parseInt(dateParts[0]);
    if (
      Keys.date_regex.test(v) &&
      monthDate <= 31 &&
      monthDate > 0 &&
      month <= 12 &&
      month > 0
    ) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      // var dateParts = v.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      if (dateObject <= today) {
        return true;
      }
      return "Date cannot be grater than today";
    }
    return "Invalid Date Format";
  },
  dateGraterThanToday: (v) => {
    var dateParts = v.split("/");
    var month = parseInt(dateParts[1]);
    var monthDate = parseInt(dateParts[0]);
    if (
      Keys.date_regex.test(v) &&
      monthDate <= 31 &&
      monthDate > 0 &&
      month <= 12 &&
      month > 0
    ) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      // var dateParts = v.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      if (dateObject >= today) {
        return true;
      }
      return "Date cannot be less than today";
    }
    return "Invalid Date Format";
  },
  dateLessThanDate: (v, date) => {
    var dateParts = v.split("/");
    var month = parseInt(dateParts[1]);
    var monthDate = parseInt(dateParts[0]);
    if (
      Keys.date_regex.test(v) &&
      monthDate <= 31 &&
      monthDate > 0 &&
      month <= 12 &&
      month > 0
    ) {
      var tempDate = new Date(date);
      tempDate.setHours(0, 0, 0, 0);
      // var dateParts = v.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      if (dateObject <= tempDate) {
        return true;
      }
      return "Invalid Date";
    }
    return "Invalid Date Format";
  },
  dateGraterThanDate: (v, date) => {
    var dateParts = v.split("/");
    var month = parseInt(dateParts[1]);
    var monthDate = parseInt(dateParts[0]);
    if (
      Keys.date_regex.test(v) &&
      monthDate <= 31 &&
      monthDate > 0 &&
      month <= 12 &&
      month > 0
    ) {
      var tempDate = new Date(date);
      tempDate.setHours(0, 0, 0, 0);
      // var dateParts = v.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      if (dateObject >= tempDate) {
        return true;
      }
      return "Invalid Date";
    }
    return "Invalid Date Format";
  },
  max250: (v) =>
    v ? v.length <= 1500 || "Max 250 words" : "This is Required.",

  matchData: (v1, v2, message) => {
    if (v1 != null && v2 != null && v1 == v2) {
      return true;
    } else {
      return message;
    }
  },
  matchRegex(regexString, text) {
    console.log(new RegExp(regexString).test(text));
    console.log(regexString);
    console.log(text);
    if (new RegExp(regexString).test(text)) {
      return true;
    } else {
      return "Invalid";
    }
  },
};
